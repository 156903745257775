<template>
  <div>
    <div v-if="loader">
      <b-card>
        <h2 class="light-blue">{{ $t("settings") }}</h2>
        <b-row>
          <b-col cols="3">
            <label>{{ $t("invoice_scoring_seller_%") }} </label>
            <b-form-input
              v-model="seller_percentage"
              type="number"
              :max="100"
              :min="0"
            ></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>{{ $t("invoice_scoring_debtor_%") }} </label>
            <b-form-input
              v-model="debtor_percentage"
              type="number"
              :max="100"
              :min="0"
            ></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>{{ $t("invoice_scoring_recourse_seller_%") }} </label>
            <b-form-input
              v-model="seller_percentage_recourse"
              type="number"
              :max="100"
              :min="0"
            ></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>{{ $t("invoice_scoring_recourse_debtor_%") }} </label>
            <b-form-input
              v-model="debtor_percentage_recourse"
              type="number"
              :max="100"
              :min="0"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <label>{{ $t("iban") }} </label>
            <b-form-input v-model="iban"></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>{{ $t("bic") }} </label>
            <b-form-input v-model="bic"></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>{{ $t("bank_name") }} </label>
            <b-form-input v-model="bank_name"></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>{{ $t("bank_address") }} </label>
            <b-form-input v-model="bank_address"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-right">
            <hr />
            <b-button variant="primary" @click="updatePercentage()">{{
              $t("save")
            }}</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h4 class="light-blue">{{ $t("rate") }}</h4>
        <b-table
          sticky-header
          :fields="ratingFields"
          :items="rates"
          responsive
          style="height: 400px; overflow-y: auto"
        >
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>
          <template #cell(upper_border)="data">
            <b-form-input v-model="data.item.upper_border"></b-form-input>
          </template>
          <template #cell(lower_border)="data">
            <b-form-input v-model="data.item.lower_border"></b-form-input>
          </template>
          <template #cell(category)="data">
            <b-form-input v-model="data.item.category"></b-form-input>
          </template>
          <template #cell(position)="data">
            <b-form-input v-model="data.item.position"></b-form-input>
          </template>
          <template #cell(rating)="data">
            <b-form-input v-model="data.item.rating"></b-form-input>
          </template>
          <template #cell(base_discount)="data">
            <b-form-input v-model="data.item.base_discount"></b-form-input>
          </template>
          <template #cell(action)="data">
            <b-button variant="success" @click="submitRating(data.item)">{{
              $t("save")
            }}</b-button>
          </template>
        </b-table>
      </b-card>
      <b-card>
        <h4 class="light-blue">{{ $t("fee_hive") }}</h4>
        <b-table
          sticky-header
          :fields="feehiveFields"
          :items="fee"
          responsive
          style="height: 400px; overflow-y: auto"
        >
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>
          <template #cell(amount_upper_border)="data">
            <b-form-input
              v-model="data.item.amount_upper_border"
            ></b-form-input>
          </template>
          <template #cell(amount_lower_border)="data">
            <b-form-input
              v-model="data.item.amount_lower_border"
            ></b-form-input>
          </template>
          <template #cell(hive_fee)="data">
            <b-form-input v-model="data.item.hive_fee"></b-form-input>
          </template>
          <template #cell(position)="data">
            <b-form-input v-model="data.item.position"></b-form-input>
          </template>
          <template #cell(action)="data">
            <b-button variant="success" @click="submitHiveFee(data.item)">{{
              $t("save")
            }}</b-button>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <h4>{{ $t('fee_hive') }}</h4>
        <b-table
            sticky-header
            :fields="feeHiveTermsFields"
            :items="termsFee"
            responsive
            style="height: 400px; overflow-y: auto"
        >
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>
          <template #cell(terms_upper_border)="data">
            <b-form-input
                v-model="data.item.terms_upper_border"
            ></b-form-input>
          </template>
          <template #cell(terms_lower_border)="data">
            <b-form-input
                v-model="data.item.terms_lower_border"
            ></b-form-input>
          </template>
          <template #cell(hive_fee)="data">
            <b-form-input v-model="data.item.hive_fee"></b-form-input>
          </template>
          <template #cell(position)="data">
            <b-form-input v-model="data.item.position"></b-form-input>
          </template>
          <template #cell(action)="data">
            <b-button variant="success" @click="submitTermsHiveFee(data.item)">{{
              $t("save")
              }}</b-button>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <h4 class="light-blue">{{ $t("time_factor") }}</h4>
        <b-table
          sticky-header
          :fields="timeFactorFields"
          :items="timeFactor"
          responsive
          style="height: 400px; overflow-y: auto"
        >
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>
          <template #cell(upper_border)="data">
            <b-form-input v-model="data.item.upper_border"></b-form-input>
          </template>
          <template #cell(lower_border)="data">
            <b-form-input v-model="data.item.lower_border"></b-form-input>
          </template>
          <template #cell(AAA)="data">
            <b-form-input v-model="data.item['AAA']"></b-form-input>
          </template>
          <template #cell(AA)="data">
            <b-form-input v-model="data.item['AA']"></b-form-input>
          </template>

          <template #cell(AA+)="data">
            <b-form-input v-model="data.item['AA+']"></b-form-input>
          </template>
          <template #cell(AA-)="data">
            <b-form-input v-model="data.item['AA-']"></b-form-input>
          </template>
          <template #cell(A+)="data">
            <b-form-input v-model="data.item['A+']"></b-form-input>
          </template>
          <template #cell(A)="data">
            <b-form-input v-model="data.item['A']"></b-form-input>
          </template>
          <template #cell(A-)="data">
            <b-form-input v-model="data.item['A-']"></b-form-input>
          </template>
          <template #cell(BBB)="data">
            <b-form-input v-model="data.item['BBB']"></b-form-input>
          </template>
          <template #cell(BBB+)="data">
            <b-form-input v-model="data.item['BBB+']"></b-form-input>
          </template>
          <template #cell(BBB-)="data">
            <b-form-input v-model="data.item['BBB-']"></b-form-input>
          </template>
          <template #cell(BB+)="data">
            <b-form-input v-model="data.item['BB+']"></b-form-input>
          </template>
          <template #cell(BB)="data">
            <b-form-input v-model="data.item['BB']"></b-form-input>
          </template>
          <template #cell(BB-)="data">
            <b-form-input v-model="data.item['BB-']"></b-form-input>
          </template>
          <template #cell(position)="data">
            <b-form-input v-model="data.item.position"></b-form-input>
          </template>
          <template #cell(action)="data">
            <b-button variant="success" @click="submitTimeFactor(data.item)">{{
              $t("save")
            }}</b-button>
          </template>
        </b-table>
      </b-card>
      <b-card>
        <h4 class="light-blue">{{ $t("overwrite_scoring_2_rules") }}</h4>
        <b-table
          :fields="scoringsFields"
          sticky-header
          :items="scorings"
          responsive
          style="height: 400px; overflow-y: auto"
        >
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>
          <template #cell(updated_at)="data">
            {{ data.item.updated_at | formatDate }}
          </template>
          <template #cell(points)="data">
            <b-form-input v-model="data.item.points"></b-form-input>
          </template>
          <template #cell(action)="data">
            <b-button variant="success" @click="updateScoring2(data.item)">{{
              $t("save")
            }}</b-button>
          </template>
        </b-table>
      </b-card>
      <b-card>

        <h4>{{ $t('get_offer_later_description') }}</h4>
          <b-form-checkbox class="mt-1" v-model="GlobalSettingsData.get_offer_later" switch variant="success" @change="change_get_offer_later"></b-form-checkbox>
      </b-card>
    </div>
    <div v-else>
      <img src="/loader.svg" />
    </div>
  </div>
</template>
<script>
import Table from "@/components/Common/Table2.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      ratingFields: [
        { label: "upper_border", align: "start", key: "upper_border" },
        { label: "lower_border", align: "start", key: "lower_border" },
        { label: "category", align: "start", key: "category" },
        { label: "position", key: "position", align: "start" },
        { label: "rating", key: "rating", align: "start" },
        { label: "base_discount", key: "base_discount", align: "start" },
        { label: "action", key: "action", align: "end" },
      ],
      feehiveFields: [
        {
          label: "amount_upper_border",
          align: "start",
          key: "amount_upper_border",
        },
        {
          label: "amount_lower_border",
          align: "start",
          key: "amount_lower_border",
        },
        { label: "position", key: "position", align: "start" },
        { label: "hive_fee", key: "hive_fee", align: "start" },
        { label: "action", key: "action", align: "end" },
      ],
      feeHiveTermsFields: [
        {
          label: "terms_lower_border",
          align: "start",
          key: "terms_lower_border",
        },
        {
          label: "terms_upper_border",
          align: "start",
          key: "terms_upper_border",
        },
        { label: "position", key: "position", align: "start" },
        { label: "hive_fee", key: "hive_fee", align: "start" },
        { label: "action", key: "action", align: "end" },
      ],
      scoringsFields: [
        { label: "type", key: "type", align: "start" },
        {
          label: "value",
          align: "start",
          key: "value",
        },
        {
          label: "points",
          align: "start",
          key: "points",
        },

        { label: "action", key: "action", align: "end" },
      ],
      timeFactorFields: [
        { label: "upper_border", align: "start", key: "upper_border" },
        { label: "lower_border", align: "start", key: "lower_border" },
        { label: "position", key: "position", align: "start" },
        { label: "Option: AAA", align: "start", key: "AAA" },
        { label: "Option: AA+", align: "start", key: "AA+" },
        { label: "Option: AA", align: "start", key: "AA" },
        {
          label: "Option: AA-",
          align: "start",
          key: "AA-",
          thStyle: { width: "1200px" },
        },
        { label: "Option: A+", align: "start", key: "A+" },
        { label: "Option: A", align: "start", key: "A" },
        { label: "Option: A-", align: "start", key: "A-" },
        { label: "Option: BBB", align: "start", key: "BBB" },
        { label: "Option: BBB+", align: "start", key: "BBB+" },
        { label: "Option: BBB-", align: "start", key: "BBB-" },
        { label: "Option: BB+", align: "start", key: "BB+" },
        { label: "Option: BB", align: "start", key: "BB" },
        { label: "Option: BB-", align: "start", key: "BB-" },
        { label: "action", key: "action", align: "end" },
      ],

      updateItem: null,
      rates: [],
      fee: [],
      termsFee: [],
      timeFactor: [],
      loader: false,
      seller_percentage: 30,
      debtor_percentage: 70,
      seller_percentage_recourse: 0,
      debtor_percentage_recourse: 0,
      bank_address: null,
      bank_name: null,
      iban: null,
      bic: null,
      scorings: [],
      GlobalSettingsData: {
        get_offer_later: false,
      },
      global_settings: null,
    };
  },
  watch: {
    seller_percentage: {
      deep: true,
      handler(val) {
        if (val > 100) {
          val = 100;
        }
        if (val < 0) {
          val = 0;
        }
        this.debtor_percentage = 100 - val;
      },
    },
    debtor_percentage: {
      deep: true,
      handler(val) {
        if (val > 100) {
          val = 100;
        }
        if (val < 0) {
          val = 0;
        }
        this.seller_percentage = 100 - val;
      },
    },
  },
  async created() {
    await this.getMarket();
    await this.getRates();
    await this.getFeeHive();
    await this.getTermsFeeHive();
    await this.getTimeFactor();
    await this.getScorings();
    await this.getGlobalSetting();
    this.loader = true;
  },
  methods: {

    async getGlobalSetting() {
      await this.$http.post("/global_settings").then((res) => {
        if (res) {
          if(res.data.value) {
            this.global_settings = res.data.value
          }

          if(this.global_settings.status) {
            this.GlobalSettingsData.get_offer_later = true
          }
        }
      })
    },

    async change_get_offer_later() {
      await this.$http.post("/global_settings/change/get_offer_later", this.GlobalSettingsData).then((res) => {
        if (res) {
          console.log(res)
        }
      })
    },

    async getMarket() {
      await this.$http.post("/settings/getMarket").then((res) => {
        if (res) {
          this.seller_percentage = res.data.value.invoice_seller_percentage;
          this.debtor_percentage = res.data.value.invoice_debtor_percentage;
          this.seller_percentage_recourse = res.data.value.invoice_seller_percentage_recourse;
          this.debtor_percentage_recourse = res.data.value.invoice_debtor_percentage_recourse;
          this.iban = res.data.value.iban;
          this.bic = res.data.value.bic;
          this.bank_name = res.data.value.bank_name;
          this.bank_address = res.data.value.bank_address;
        }
      });
    },
    updatePercentage() {
      this.$http
        .post("/settings/updatePercentage", {
          seller: this.seller_percentage,
          debtor: this.debtor_percentage,
          seller_recourse: this.seller_percentage_recourse,
          debtor_recourse: this.debtor_percentage_recourse,
          bank_name: this.bank_name,
          bank_address: this.bank_address,
          iban: this.iban,
          bic: this.bic,
        })
        .then((res) => {
          if (res) {
            this.showSuccessMessage();
          }
        });
    },
    getRates() {
      this.$http.post("/settings/getRating").then((res) => {
        if (res) {
          this.rates = res.data.value;
        }
      });
    },
    getFeeHive() {
      this.$http.post("/settings/getFeeHive").then((res) => {
        if (res) {
          this.fee = res.data.value;
        }
      });
    },
    getTermsFeeHive() {
      this.$http.post("/settings/getTermsFee").then((res) => {
        console.log(111, res);
        if (res) {
          this.termsFee = res.data.value;
          console.log(222, this.termsFee)
        }
      });
    },
    getTimeFactor() {
      this.$http.post("/settings/getTimeFactor").then((res) => {
        if (res) {
          this.timeFactor = res.data.value;
        }
      });
    },
    getScorings() {
      this.$http
        .post("/company/admin/getScoring2OverwriteRules")
        .then((res) => {
          if (res) {
            this.scorings = res.data.value;
          }
        });
    },
    async updateScoring2(item) {
      let data = {
        points: item.points,
      };
      this.$http
        .post("/company/admin/updateScoring2OverwriteRules/" + item.id, data)
        .then((res) => {
          if (res) {
            this.showSuccessMessage();
          }
        });
    },
    showSuccessMessage() {
      let text = this.$t("updated_successfully");
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    submitTimeFactor(item) {
      this.updateItem = item;
      this.$http
        .post("/settings/updateTimeFactor/" + item.id, this.updateItem)
        .then((res) => {
          if (res) {
            this.getTimeFactor();
            this.showSuccessMessage();
          }
        });
    },
    submitRating(item) {
      this.updateItem = item;
      this.$http
        .post("/settings/updateRating/" + item.id, this.updateItem)
        .then((res) => {
          if (res) {
            this.getRates();
            this.showSuccessMessage();
          }
        });
    },
    submitHiveFee(item) {
      this.updateItem = item;
      this.$http
        .post("/settings/updateFeeHive/" + item.id, this.updateItem)
        .then((res) => {
          if (res) {
            this.getFeeHive();
            this.showSuccessMessage();
          }
        });
    },
    submitTermsHiveFee(item) {
      this.updateItem = item;
      this.$http
        .post("/settings/updateTermsFeeHive/" + item.id, this.updateItem)
        .then((res) => {
          if (res) {
            this.getFeeHive();
            this.showSuccessMessage();
          }
        });
    },
  },
};
</script>
